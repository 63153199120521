// consumer_t_od_hotel_order_interim_hotelOrderPolicyTips
const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_hotelOrderPolicyTips = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/hotelOrderPolicyTips',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_hotelOrderPolicyTips;
