// consumer_admin_hotel_order_createHotelOrder
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/admin/createHotelOrderUsingPOST
const consumer_t_od_hotel_order_interim_createOrderByOnBusiness = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/createOrderByOnBusiness',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_createOrderByOnBusiness;
