import consumer_approveFlow_matchingByReserve from '@/lib/data-service/haolv-default/consumer_approveFlow_matchingByReserve'
import {approveFlow_matching} from '@/lib/data-service/haolv-default/design';
export default {
    data() {
        return {
            nodeList: [],
        }
    },
    props: {
        businessRuleControl: {
            type: [Number, String],
            default: 1,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init() {
            let nodeList = [];
            let hasNodeList = false;
            return new Promise(async (resolve, reject) => {
                await this.$store.state.workflowDefer.promise;
                await this.$store.state.userInfoDefer.promise;
                const matchApi = this.$store.state.workflow === '1' ? approveFlow_matching({companyId: this.$store.state.userInfo.companyId, userId: this.$store.state.userInfo.id, groupId: 1}) : consumer_approveFlow_matchingByReserve();
                consumer_approveFlow_matchingByReserve().then(res => {
                    nodeList = this.$store.state.workflow === '1' ? res.result : res.datas.nodeList;
                    if (nodeList && nodeList.length > 0) {
                        hasNodeList = true;
                        nodeList.forEach(value => {
                            value.text = '审批通过';
                            if (this.$store.state.workflow === '1') {
                                value.nodeName
                            }
                        });
                        nodeList.unshift({
                            nodeName: '创建订单',
                            text: '提交审批'
                        });
                        nodeList.push({
                            nodeName: '预订完成',
                            text: ''
                        });

                        this.nodeList = nodeList
                    }

                    resolve(hasNodeList)
                }).catch(() => {
                    resolve(hasNodeList)
                })
            })
        },
    }
}
